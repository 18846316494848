<template>
  <div style="margin-left: 60px; margin-top: 25px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('DeviceManage.DeviceGroup.DeviceGroupName')+'：'" prop="name">
        <el-input
          v-model.trim="ruleForm.name"
          onKeyPress="if (event.key === 'Enter') { event.preventDefault(); }"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.rolePage.describe') + '：'">
        <!-- 添加属性word-break:break-all;可以处理IE 添加属性word-wrap:break-word;可以处理火狐  中文和数字换行的问题-->
        <el-input
          type="textarea"
          v-model.trim="ruleForm.memo"
          style="width: 250px; word-break: break-all; word-wrap: break-word"
          :rows="4"
          show-word-limit
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DeviceGroup.AssociatedDevices')+'：'">
        <el-button
          type="primary"
          round
          icon="el-icon-plus"
          @click="addDevice"
          size="small"
          >{{$t('DeviceManage.DeviceGroup.Device')}}</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="false"
          size="small"
          @click="submit('ruleForm')"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 添加关联设备 -->
    <elDialog
      ref="elDialog"
      class="dialog"
      :title="'选择设备'"
      :width="'1750px'"
      :flagbtn="flagbtn"
      :determineBtn="determineBtn"
      :cancelBtn="cancelBtn"
      :btnTitle="$t('Delivery.distributionManagement.join')"
      :num="'500px'"
      :dialogVisible="showUsersFlag"
      v-if="showUsersFlag"
      @determine="determine"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <div class="box-corn" style="padding: 0px 2px">
        <selectDevices
          ref="selectUsers"
          @rightCheckData="handRightCheckData"
          :editData="editDevicesData"
        />
      </div>

      <div v-if="flagbtn" slot="footer" class="diailog-footer">
        <el-button
          v-if="determineBtn"
          size="small"
          type="primary"
          class="btn-determine"
          @click="determine"
          >{{ $t("Delivery.distributionManagement.join") }}</el-button
        >
        <el-button
          v-if="cancelBtn"
          size="small"
          type="primary"
          class="btn-cancel"
          @click="cancel"
          >{{ $t("Delivery.distributionManagement.cancel") }}</el-button
        >
      </div>
    </elDialog>
  </div>
</template>
    
<script>
import selectDevices from "./selectDevices.vue";
import elDialog from "@/components/elDialog"; //详情弹框
export default {
  components: { selectDevices, elDialog },
  props: {
    editObj: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: () => {
        return {};
      },
    },
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t("UserManage.rolePage.NotSupported")));
      } else {
        callback();
      }
    };
    return {
      addFlagShow:false,//加入后回显查看点击取消再保存
      input: "", //查询输入框
      pageSize: 10, //每页显示条数
      currentPage: 1, //当前页码
      flagbtn: true,
      cancelBtn: true,
      determineBtn: true,
      save: false, 
      currentNodeKey: "",
      visibaelFlag: false, //提示消息默认--关闭
      showUsersFlag: false, //关联用户弹框
      titleInfo: "", // 提示框的提示语
      ruleForm: {
        name: "",
        memo: "",
        deviceList: [],
      },
      treeData: [],
      checkedList: [],
      checkData: [], //勾选复选框数据
      checkDataId: [], //勾选复选框数据id
      //默认展开节点数据
      expandedList: [],
      resArr: [], //循环使用数组
      checked: false, // 是否有选中
      rules: {
        name: [
          { required: true, message: this.$t('DeviceManage.DeviceGroup.devGroupPlaceholder'), trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
          { min: 1, max: 20, message: this.$t('DeviceManage.DeviceGroup.Message'), trigger: "blur" },
        ],
      },

      defaultProps: {
        children: "children",
        label: "label",
      },
      modelForm: {
        name: "",
        memo: "",
        treeData: "",
      },
      receivedData: [], //已勾选数据
      receivedDataID: [], //已勾选数据ID
      editDevicesData: [], //回显的关联设备数组
      OtherObject:{},
    };
  },
  created() {
    if (this.editObj.id) {
      this.ruleForm = this.editObj;
      this.getListData();
    }
  },
  mounted() {
  },
  methods: {
    //限制输入特殊字符
    // btKeyUp(e) {
    //   e.target.value = e.target.value
    //     .replace(
    //       /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
    //       ''
    //     )
    //     .replace(/\s/g, '')
    // },
    //数组去重
    arrayFilter(val) {
      const map = new Map();
      const uniqueArray = val.filter((item) => {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          return true;
        }
        return false;
      });
      console.log(uniqueArray, "数组去重");
      return uniqueArray;
    },
    //获取列表
    async getListData() {
      var param = {
        id: this.editObj.id,
        deviceName: this.input,
        currentPage: this.currentPage,
        pageSize: 100000,
      };
      const res = await this.$axios.post(
        "/httpServe/deviceGroup/getDeviceInfo",
        param,
        true
      );
      // console.log(res.data.content, "关联数据");
      if (res.data.content == null) {
        res.data.content = [];
      }
      this.editDevicesData = res.data.content; //回显的关联设备数组
      this.receivedDataID = this.editDevicesData.map((item) => item.id);
      // console.log(this.receivedDataID, "新数组id");
    },
    // 添加关联设备
    addDevice() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.showUsersFlag = true;
          this.getListData();
          this.receivedDataID = this.editDevicesData.map((item) => item.id);
          // console.log(this.receivedDataID, "点击+设备");
        }else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹框加入按钮
    determine() {
      console.log(this.$refs.selectUsers.handleChange(),"加入");
      this.OtherObject = this.$refs.selectUsers.handleChange();
      this.receivedDataID = this.OtherObject.arr.map((obj) => obj.id);
      if(this.OtherObject.arr.length>0){
        this.addData();
      }
      this.showUsersFlag = false;
    },
    async addData() {
      // console.log(this.editObj.id,"this.editObj.id--点击弹框加入按钮后");
      if (this.editObj.id) {
        var params = {
          id: this.editObj.id,
          memo: this.ruleForm.memo,
          deviceList: this.receivedDataID,
          name: this.ruleForm.name,
        };
        const res = await this.$axios.post("/httpServe/deviceGroup/update", params, true);
        if (res.data == null) {
        // 设备组名称不能为空
          this.$message({
            message: this.$t('DeviceManage.DeviceGroup.Error1'),
            type: "error",
            offset: 100,
          });
        }else{
          this.save = false;
        }
      } else {
        var params = {
          memo: this.ruleForm.memo,
          deviceList: this.receivedDataID,
          name: this.ruleForm.name,
        };
        const res = await this.$axios.post("/httpServe/deviceGroup/insert", params, true);
        if (res.data == null) {
          // 设备组名称不能为空
          this.$message({
            message: this.$t('DeviceManage.DeviceGroup.Error1'),
            type: "error",
            offset: 100,
          });
        } else if(res.data == -1 ){
          // 设备组名称已存在的标识
          this.save = true;
          // 回显加入弹框里的数据
          // this.getTableList();
        } else {
          this.save = false;
          // 回显加入弹框里的数据
          this.getTableList();
        }
      }
    },
    async getTableList() {
      var params = {
        currentPage: 1,
        pageSize: 10,
        orderColume: "update_time",
        orderRule: "DESC",
        name: "",
      };

      const res = await this.$axios.post(
        "/httpServe/deviceGroup/getDataInfo",
        params,
        true
      );
      console.log(res.data.content[0],"最新一条");
      this.editObj.id = res.data.content[0].id;
    },
    //弹框回调事件
    handleClose() {
      // this.OtherObject=this.$refs.selectUsers.handleChange()
      this.visibaelFlag = false; //提示消息  关闭
      this.showUsersFlag = false;
      console.log("弹框取消");
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
    //关闭按钮
    cancel() {
      this.showUsersFlag = false;
    },
    //子传父右侧表格的数据
    handRightCheckData(data) {
      // console.log(data, "子传父右侧表格的数据");
      this.receivedData = data;
      // console.log(this.receivedData, "this.receivedData");
    },
    async addDeviceGroup() {
      var params = {
        memo: this.ruleForm.memo,
        deviceList: this.receivedDataID,
        name: this.ruleForm.name,
      };
      const res = await this.$axios.post(
        "/httpServe/deviceGroup/insert",
        params,
        true
      );
      if (res.data == -1) {
        this.$message({
          message: this.$t('DeviceManage.DeviceGroup.Error'),
          type: "error",
          offset: 100,
        });
        this.receivedDataID = [];
        this.editDevicesData = [];
      } else {
        var flag = 1;
        this.$emit("eventSave", flag);
        this.receivedDataID = [];
      }
    },
    async editDeviceGroup() {
      var params = {
        id: this.editObj.id,
        memo: this.ruleForm.memo,
        deviceList: this.receivedDataID,
        name: this.ruleForm.name,
      };
      const res = await this.$axios.post(
        "/httpServe/deviceGroup/update",
        params,
        true
      );
      if (res.data == -1) {
        this.$message({
          message: this.$t('DeviceManage.DeviceGroup.Error'),
          type: "error",
          offset: 100,
        });
        this.receivedDataID = [];
        this.editDevicesData = [];
        // 保存失败，设备组名称已存在
        // this.visibaelFlag = true;
        // this.titleInfo =
        //   this.$t("UserManage.rolePage.SaveFailed") + res.message;
      } else {
        var flag = 1;
        this.$emit("eventSave", flag);
        this.receivedDataID = [];
      }
    },
    // 保存
    submit(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.editObj.id,"this.editObj.id点保存时");
          if (this.editObj.id) {
            console.log(this.save,"save111");
            if(this.save == true){
              this.addDeviceGroup();
            }else{
              this.editDeviceGroup();
            }
          }else{
            this.addDeviceGroup();
          } 
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    check(data, checked, indeterminate) {
      this.checked = checked;
      if (checked) {
        this.checkData.push(data);
        this.checkDataId.push(data.id);
      } else {
        const index = this.checkData.findIndex((d) => d.id === data.id);
        this.checkData.splice(index, 1);
        this.checkDataId.splice(index, 1);
      }
    },
    setChecked(key, checked, deep) {
      // console.log(key, checked, deep)
    },
    nodeClick(data) {
      if (this.checkedList.includes(data.id)) {
        this.checkedList.splice(this.checkedList.indexOf(data.id), 1);
        // 判断是否父节点
        if (data.children) {
          data.children.forEach((item) => {
            this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
          });
        }
      } else if (data.children) {
        data.children.forEach((item) => {
          if (this.checkedList.includes(item.id)) {
            this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
          } else {
            this.checkedList.push(item.id);
          }
        });
      } else {
        this.checkedList.push(data.id);
      }

      // 判断是否父节点
      if (data.children) {
        if (this.expandedList.includes(data.id)) {
          this.expandedList.splice(this.expandedList.indexOf(data.id), 1);
        } else {
          this.expandedList.push(data.id);
        }
      } else {
        this.expandedList = [];
      }
      this.$refs.tree.setCheckedKeys(this.checkedList.concat(this.checkDataId));
      // console.log(data, this.checkedList, this.expandedList)
    },

    // 遍历树根据子节点的id查找父节点id,如果是根节点返回当前id
    findParent(data, id) {
      let parent = id;
      data.forEach((item) => {
        if (item.children) {
          if (item.children.find((child) => child.id === id)) {
            parent = item;
          } else {
            parent = this.findParent(item.children, id);
          }
        }
      });
      return parent;
    },
    // 根据父节点id查找所有子节点
    findChildren(data, id) {
      let children = [];
      data.forEach((item) => {
        if (item.children) {
          if (item.id === id) {
            children = item.children;
          } else {
            children = this.findChildren(item.children, id);
          }
        }
      });
      return children;
    },
    // 判断是否有子集
    isParent(data, id) {
      let isParent = false;
      data.forEach((item) => {
        if (item.children) {
          if (item.children.find((child) => child.id === id)) {
            isParent = true;
          } else {
            isParent = this.isParent(item.children, id);
          }
        }
      });
      return isParent;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
.box {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 5px 0px;
  width: 250px;
  height: 500px;
  overflow: auto;
}
</style>
    <style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>