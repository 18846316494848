<template>
  <div>
    <el-input
      size="small"
      style="width: 180px; margin-bottom: 10px"
      v-model="input"
      @keyup.enter.native="search"
      :placeholder="'请输入设备名称'"
      ><i
        @click="search"
        slot="suffix"
        class="el-input__icon el-icon-search"
        style="cursor: pointer"
      ></i
    ></el-input>
    <div class="bigBox">
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="mini"
        stripe
        ref="multipleTable"
        :data="tableData"
        class="tableClass"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="deviceName"
          :label="'设备名称'"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="osId" :label="'操作系统'">
          <template v-slot="{ row }">
            <span v-if="row.osId == 20"> Android </span>
            <span v-if="row.osId == 10"> iOS </span>
            <span v-if="row.osId == 30"> Windows </span>
            <span v-if="row.osId == 40"> Linux </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          :label="'设备状态'"
        >
          <template v-slot="{ row }">
            <!-- 等待注册 -->
            <template v-if="row.status == 10">
              <span style="color: #575656">{{
                $t("DeviceManage.Device.Pendingregistration")
              }}</span>
            </template>
            <!-- 未激活 -->
            <template v-if="row.status == 5">
              <span style="color: #2ba245">{{
                $t("DeviceManage.Device.BasicNews.Notactive")
              }}</span>
            </template>
            <!-- 已激活 -->
            <template v-if="row.status == 15">
              <span style="color: #575656">{{
                $t("DeviceManage.Device.BasicNews.Active")
              }}</span>
            </template>
            <!-- <template v-if="row.status == 20">
                <span style="color: #1e8ae5">取消激活</span>
              </template> -->
            <!-- 注销 -->
            <template v-if="row.status == 25">
              <span style="color: #1e8ae5">{{
                $t("DeviceManage.Device.SignOUT")
              }}</span>
            </template>
            <!-- 停用 -->
            <template v-if="row.status == 35">
              <span style="color: #ff0000">{{
                $t("public.Outofservice")
              }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="bindStatus"
          :label="'绑定状态'"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <template v-if="row.bindStatus == 1">
              <span style="color: #2ba245">{{
                $t("DeviceManage.Device.Bind")
              }}</span>
            </template>
            <template v-if="row.bindStatus == 0">
              <span style="color: #575656">{{
                $t("DeviceManage.Device.Untie")
              }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="deviceConIMStatus.lastLoginTime"
          :label="'最近登录时间'"
          width="180"
        >
          <template v-slot="{ row }">
            <span v-if="row.deviceConIMStatus.lastLoginTime == null"></span>
            <span v-else>{{ row.deviceConIMStatus.lastLoginTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <p style="margin-top: 10px">
        {{ $t("public.total") }}<b>{{ total }}</b
        >{{ $t("UserManage.adminPage.Records") }}
      </p> -->
      <!-- 分页-->
      <pagination
        style="margin-right: 10px"
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    pagination,
  },
  props: {
    rowId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      input: "", //查询输入框
      tableData: [], //表格数据
      total: 0, //数据数量
      pageSize: 10, //每页显示条数
      currentPage: 1, //当前页码
    };
  },
  created() {
    this.getListData();
  },
  mounted() {},
  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      //调用列表方法
      this.getListData();
    },
    //获取列表
    async getListData() {
      var param = {
        id: this.rowId,
        deviceName: this.input,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      console.log(param, "param获取列表");
      const res = await this.$axios.post(
        "/httpServe/deviceGroup/getDeviceInfo",
        param,
        true
      );
      console.log(res, "res");
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    //查询
    search() {
      if (this.input.length > 0) {
        //   let arr = this.tableData;
        //   let list = arr.filter(
        //     (item) => item.deviceName.toLowerCase().indexOf(this.input.toLowerCase()) !== -1
        //   );
        //   this.tableData = list;
        //   this.total = res.data.total;
        // } else {
        this.currentPage = 1;
      }
      this.getListData();
      // if (!this.input) {
      //   this.getListData(this.deviceCount)
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
